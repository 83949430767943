import { useState, useEffect, useMemo, useCallback } from "react"
import tw, { styled } from "twin.macro"

import {
  ImageTilesExperienceProps,
  ExperienceTileFields,
} from "components/dynamic"

import { H3, H6 } from "styles/Text"
import { colors, fontBase, breakpoint } from "styles/Variables"

import Container from "components/Container"
import Grid from "components/Grid"
import Column from "components/Column"
import SecondaryButton from "components/Buttons/Secondary"
import API from "lib/api"

import TileGrid, { TileTypes } from "components/TileGrid"
import { SelectEl } from "components/Forms"

const FilterSelectEl = styled(SelectEl)`
  border-bottom: 1px solid ${colors["light-grey"]};
  padding: 8px 0;
  border-radius: 0;
  font-size: ${24 / fontBase}rem;
  font-weight: 300;
  background-position: center right 10px;
  line-height: 1.4;
  ${breakpoint.md`
    background-position: center right 30px;
    font-size: ${28 / fontBase}rem;
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
    padding: 8px 30px;
  `}
`

export const FilterSelect = ({
  label,
  name,
  required = false,
  children,
  ...props
}) => (
  <>
    <label htmlFor={name} tw="text-dark-grey">
      <H6 as="span">
        {label} {!!required && "*"}
      </H6>
    </label>
    <FilterSelectEl id={name} name={name} {...props} required={!!required}>
      {children}
    </FilterSelectEl>
  </>
)

export const Experiences = ({
  Title,
  ExperiencePages = [],
}: ImageTilesExperienceProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const [hasFetched, setHasFetched] = useState(false)
  const [experiences, setExperiences] = useState(ExperiencePages)

  const [location, setLocation] = useState("")

  const allLocations = useMemo(
    () =>
      ExperiencePages.filter((xp) => !!xp.Location)
        .reduce((acc, { Location }) => [...acc, Location], [])
        .filter(
          (loc, idx, self) => idx === self.map(({ id }) => id).indexOf(loc?.id)
        ),
    [ExperiencePages]
  )

  const fetchAllExperiences = useCallback(async () => {
    setHasFetched(true)
    setIsFetching(true)
    const { data } = await API.gql(`
    {
      Experiences ${ExperienceTileFields}
    }
    `)

    if (data?.data?.Experiences) {
      setExperiences(data?.data?.Experiences)
    }
    setIsFetching(false)
  }, [])

  useEffect(() => {
    if (ExperiencePages?.length > 0) {
      return
    }
    fetchAllExperiences()
  }, [ExperiencePages, fetchAllExperiences])

  // Filter experiences by location
  useEffect(() => {
    const newExperiences = ExperiencePages.filter(
      (xp) => !location || xp?.Location?.id === location
    )
    setExperiences(newExperiences)
  }, [location, ExperiencePages])

  return (
    <section tw="py-32">
      <Container>
        <H3 as="h2" tw="text-center mb-7.5 lg:mb-15">
          {Title || "Experiences on country"}
        </H3>
        {ExperiencePages?.length > 0 && (
          <Grid halign="center">
            <Column size={{ md: 10 / 12 }}>
              <Grid halign="center">
                <Column size={{ lg: 1 / 2 }}>
                  <FilterSelect
                    label="Select location"
                    name="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option value="">All locations</option>
                    {allLocations.map(({ id, Name }) => (
                      <option value={id} key={id}>
                        {Name}
                      </option>
                    ))}
                  </FilterSelect>
                </Column>
              </Grid>
            </Column>
          </Grid>
        )}
        <TileGrid tiles={experiences} type={TileTypes.EXPERIENCE} tw="mt-7.5" />
        {!hasFetched && (
          <div tw="text-center mt-7.5">
            <SecondaryButton
              as="button"
              type="button"
              dark
              tw="w-56"
              disabled={isFetching}
              onClick={fetchAllExperiences}
            >
              Show all
            </SecondaryButton>
          </div>
        )}
      </Container>
    </section>
  )
}

export default Experiences
