import styled from "styled-components"
import tw from "twin.macro"
import { BodyDetail, H6 } from "styles/Text"
import { colors } from "styles/Variables"
import CheckmarkIcon from "icons/Checkmark"

export const InputEl = styled.input`
  display: block;
  padding: 15px 20px;
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  color: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
`

export const SelectEl = styled(InputEl).attrs(() => ({
  as: "select",
}))`
  appearance: none;
  background-image: url("/assets/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: center right 16px;
`

export const CheckboxEl = styled.input(() => [
  tw`block
  w-5 h-5
  rounded-md
  border border-solid border-primary bg-transparent appearance-none
  transition duration-300
  focus:outline-none
  focus:ring-1 focus:ring-primary focus:ring-offset-1
  checked:bg-primary`,
])

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
`

export const ErrorMessage = styled.div.attrs(() => ({
  role: alert,
}))`
  color: ${colors.primary};
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  border: 1px solid ${colors.primary};
`

export const CheckboxInput = ({ name, label, ...props }) => {
  const inputId = props.id || `${name}-checkbox`
  return (
    <div tw="flex items-start">
      <div tw="relative mr-2">
        <CheckboxEl
          name={name}
          id={inputId}
          css={!props.disabled && tw`cursor-pointer`}
          type="checkbox"
          className="peer"
          {...props}
        />
        <CheckmarkIcon tw="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white w-4 h-4 transition-transform duration-300 transform scale-0 peer-checked:scale-100" />
      </div>
      <BodyDetail as="label" htmlFor={inputId}>
        {label}
      </BodyDetail>
    </div>
  )
}

export const Input = ({
  name,
  required = false,
  label,
  dark = false,
  ...props
}) => (
  <label htmlFor={name}>
    <H6 as="span">
      {label} {!!required && "*"}
    </H6>

    <InputEl
      style={{ borderStyle: "solid" }}
      css={[
        tw`mt-3 border`,
        dark
          ? tw`border-black border-opacity-60`
          : tw`border-white border-opacity-60`,
      ]}
      id={name}
      name={name}
      {...props}
      required={!!required}
    />
  </label>
)

export const Select = ({
  label,
  name,
  required = false,
  children,
  ...props
}) => (
  <>
    <H6 as="label" htmlFor={name}>
      {label}
      {!!required && "*"}
    </H6>
    <SelectEl tw="mt-3" id={name} name={name} {...props} required={!!required}>
      {children}
    </SelectEl>
  </>
)
